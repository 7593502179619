<template>
	<v-card class="mOrder-view tw-rounded-3xl tw-p-4 lg:tw-p-10">
		<div class="tw-flex tw-justify-between tw-w-full">
			<div class="tw-flex tw-justify-between tw-items-center tw-w-full">
				<div>
					<h2 class="tw-text-22 tw-font-semibold tw-text-black">
						{{ $t('orderDetails') }}
					</h2>
					<div
						class="tw-flex tw-flex-wrap tw-items-center tw-space-s-3.5 tw-text-black tw-text-opacity-25"
					>
						<span class
							>{{ $t('orderNumber') }} {{ $t('colon') }}
							{{ mOrder.orderNumber }}</span
						>
						<div class="tw-flex tw-items-center tw-space-s-3.5">
							<span
								class="tw-w-2 tw-h-2 tw-block tw-bg-F2 tw-rounded-full"
							></span>
							<span>
								{{ $m(mOrder.createdAt).format('YYYY-MM-DD HH:MM A') }}
							</span>
						</div>
					</div>
				</div>

				<div class="tw-flex tw-space-s-3">
					<div v-if="!$currentUser.isBuyer" class="tw-w-44">
						<BaseStatusChanger
							v-model="mOrder.status"
							:items="translatedStatuses"
							:on-change="(v) => onChangeStatus(mOrder, v)"
							height="50"
							@change="mOrder = $event.order"
						/>
					</div>
					<v-tooltip bottom color="primary">
						<template #activator="{ on, attrs }">
							<v-dialog
								v-model="dialogOrderTracking"
								scrollable
								max-width="512"
							>
								<template #activator="{ on: on2, attrs: attrs2 }">
									<v-btn
										v-on="{ ...on, ...on2 }"
										v-bind="{ ...attrs, ...attrs2 }"
										tile
										icon
										outlined
										depressed
										width="50"
										height="50"
										color="primary"
										class="tw-rounded-md"
									>
										<v-icon>mdi-map-marker-distance</v-icon>
										<!-- <v-icon>mdi-format-list-checks</v-icon> -->
									</v-btn>
								</template>
								<v-card>
									<v-card-title
										class="tw-flex tw-justify-between tw-items-center"
									>
										<span>{{ $t('order-tracking') }}</span>
										<v-btn
											icon
											color="error"
											@click="dialogOrderTracking = false"
										>
											<v-icon color="error"> mdi-close </v-icon>
										</v-btn>
									</v-card-title>
									<v-card-subtitle>{{
										$t('see-progress-of-order')
									}}</v-card-subtitle>
									<v-divider />
									<v-card-text>
										<v-timeline align-top dense class="tw-mt-4">
											<v-timeline-item
												v-for="(
													tracking, index
												) in mOrder.trackings"
												:key="index"
												small
												:color="index === 0 ? 'primary' : 'grey'"
											>
												<v-row class="pt-1">
													<v-col cols="4">
														<strong>
															{{
																$m(tracking.pivot.time).format(
																	'hh:mm A'
																)
															}}
														</strong>
														<div class="text-caption">
															{{
																$m(tracking.pivot.time).format(
																	'DD MMMM YYYY'
																)
															}}
														</div>
													</v-col>
													<v-col>
														<strong>
															<BaseStatus
																:status="
																	tracking.pivot
																		.situationStatus
																"
															/>
														</strong>
														<div class="text-caption">
															{{
																$t('updated-by', [
																	tracking.name,
																])
															}}
														</div>
													</v-col>
												</v-row>
											</v-timeline-item>
										</v-timeline>
									</v-card-text>
								</v-card>
							</v-dialog>
						</template>
						<span> {{ $t('track-your-order') }} </span>
					</v-tooltip>
					<v-btn
						:loading="printLoading"
						tile
						icon
						outlined
						depressed
						width="50"
						height="50"
						color="primary"
						class="tw-rounded-md"
						@click="handlePrint"
					>
						<icon-print />
					</v-btn>
				</div>
			</div>
		</div>
		<div class="tw-my-9">
			<div
				class="tw-rounded-2xl tw-bg-FD lg:tw-px-7 lg:tw-py-6 tw-w-full tw-grid tw-grid-cols-1 tw-gap-4 md:tw-grid-cols-2 lg:tw-grid-cols-3"
			>
				<div class="tw-flex tw-flex-1 tw-space-s-5">
					<div class="tw-flex tw-items-center tw-flex-none tw-px-5">
						<img
							src="@/assets/images/orderDetailsAvatar.svg"
							alt
							class="tw-my-auto"
							srcset
						/>
					</div>
					<div class="tw-flex tw-flex-col tw-ms-5">
						<span class="tw-text-C6 tw-leading-5">
							{{ $t('client') }}
						</span>
						<div class="tw-text-base tw-font-semibold tw-mt-4">
							{{ mOrder.creator.name }}
						</div>
						<div class="tw-mt-3 tw-mb-1.5">
							<v-icon color="#DEDEDE" left>mdi-email-outline</v-icon>
							{{ mOrder.creator.email }}
						</div>
						<div class="tw-mb-1.5">
							<v-icon color="#DEDEDE" left>mdi mdi-phone-outline</v-icon>
							{{ mOrder.creator.phone }}
						</div>
					</div>
				</div>
				<div class="tw-flex tw-flex-1">
					<div class="tw-flex tw-items-center tw-flex-none tw-px-5">
						<img
							src="@/assets/images/orderDetailsPayment.svg"
							alt=""
							class="tw-my-auto"
							srcset=""
						/>
					</div>
					<div class="tw-flex tw-flex-col tw-ms-5">
						<span class="tw-text-C6 tw-leading-5">{{ $t('order') }}</span>
						<div class="tw-text-base tw-font-semibold tw-mt-4">
							{{ $t('payment') }}
						</div>
						<div class="tw-mt-3 tw-mb-1.5">
							<v-icon color="#DEDEDE" left>mdi mdi-currency-usd</v-icon>
							{{ mOrder.payments[0].paymentMethod }}
						</div>
						<div class="tw-mb-1.5">
							<v-icon size="23" color="#DEDEDE" left
								>mdi mdi-circle-small
							</v-icon>
							{{ mOrder.payments[0].paymentStatus }}
						</div>
					</div>
				</div>
				<div class="tw-flex tw-flex-1">
					<div class="tw-flex tw-items-start tw-flex-none tw-px-5">
						<img
							src="@/assets/images/orderDetailsDelivery.svg"
							alt=""
							class="tw-my-auto"
							srcset=""
						/>
					</div>
					<div class="tw-flex tw-flex-col tw-ms-5">
						<span class="tw-text-C6 tw-leading-5">{{
							$t('delivery')
						}}</span>
						<div class="tw-text-base tw-font-semibold tw-mt-4">
							{{ $get(mOrder, 'shippingAddress.0.city') }}
						</div>
						<div>{{ $get(mOrder, 'shippingAddress.0.streetName') }}</div>
					</div>
				</div>
			</div>
			<div class>
				<template v-if="mOrder.products.length">
					<v-subheader class="tw-font-bold">
						{{ $t('products') }}
					</v-subheader>
					<v-container fluid>
						<template v-for="(product, index) in mOrder.products">
							<v-row v-if="product" :key="index">
								<v-col
									md="5"
									cols="12"
									class="tw-flex tw-items-center tw-space-s-8"
								>
									<v-img
										:src="
											$image(
												$get(product, 'attachments.0.attachedLink')
											)
										"
										cover
										width="70"
										height="70"
										ref="imgRef"
										class="tw-rounded-lg tw-flex-none"
										@error="
											$onImgError({
												index,
												size: '70',
												ref: $refs.imgRef,
											})
										"
									/>
									<div class="tw-flex-1 tw-overflow-hidden">
										<h4
											class="tw-mb-0 tw-font-medium tw-text-xl tw-truncate"
										>
											{{ product.name }}
										</h4>
										<p
											class="tw-mb-0 tw-font-normal tw-text-sm tw-truncate"
										>
											{{ product.description }}
										</p>
									</div>
								</v-col>
								<v-col
									cols="2"
									class="tw-text-17px tw-my-auto tw-text-center"
								>
									{{ product.pivot.quantity }}
									{{ $t('units') }}
								</v-col>
								<v-col cols="2" class="tw-my-auto tw-text-center">
									{{ $t('sar') }}
									{{ product.pivot.priceWod.toFixed(2) }}
								</v-col>
								<v-col
									cols="3"
									class="tw-text-xl tw-font-medium tw-text-right tw-my-auto"
								>
									{{ $t('sar') }}
									{{
										$commafy(
											(
												product.pivot.priceWod *
												product.pivot.quantity
											).toFixed(2)
										)
									}}
								</v-col>
							</v-row>
							<v-divider
								v-if="index != mOrder.products.length - 1"
								:key="'d-' + index"
								class="tw-my-2"
							/>
						</template>
					</v-container>
				</template>

				<template v-if="mOrder.orderOffers.length">
					<v-subheader class="tw-font-bold">
						{{ $t('offers') }}
					</v-subheader>
					<v-expansion-panels v-model="offerPanels" multiple flat>
						<v-expansion-panel
							v-for="(item, index) in mOrder.orderOffers"
							:key="item.id"
							class="tw-border-b tw-border-solid"
						>
							<v-expansion-panel-header hide-actions class="tw-p-3">
								<v-row>
									<v-col
										md="5"
										cols="12"
										class="tw-flex tw-items-center tw-space-s-8"
									>
										<v-img
											:src="
												$image(
													$get(item, 'attachments.0.attachedLink')
												)
											"
											cover
											width="70"
											height="70"
											ref="oImgRef"
											class="tw-rounded-lg tw-flex-none"
											@error="
												$onImgError({
													index,
													size: '70',
													ref: $refs.oImgRef,
												})
											"
										/>
										<div class="tw-flex-1 tw-overflow-hidden">
											<h4
												class="tw-mb-0 tw-font-medium tw-text-xl tw-truncate"
											>
												{{ item.offer.name }}
											</h4>
											<p
												class="tw-mb-0 tw-font-normal tw-text-sm tw-truncate"
											>
												{{ item.products.length }}
												{{ $t('products') }}

												<v-icon size="20">mdi-chevron-down</v-icon>
											</p>
										</div>
									</v-col>
									<v-col
										cols="2"
										class="tw-text-17px tw-my-auto tw-text-center"
									>
										{{ item.quantity }}
										{{ $t('units') }}
									</v-col>
									<v-col cols="2" class="tw-my-auto tw-text-center">
										{{ $t('sar') }}
										{{ $commafy(item.priceWod.toFixed(2)) }}
									</v-col>
									<v-col
										cols="3"
										class="tw-text-xl tw-font-medium tw-text-right tw-my-auto"
									>
										{{ $t('sar') }}
										{{
											$commafy(
												(item.priceWod * item.quantity).toFixed(2)
											)
										}}
									</v-col>
								</v-row>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-container fluid>
									<v-row>
										<v-col
											cols="12"
											class="tw-bg-gray-100 tw-rounded-lg"
										>
											<div class="tw-font-bold tw-mb-3">
												{{ $t('offer-products') }}
											</div>
											<div
												v-for="(product, index) in item.products"
												:key="product.id"
												class="tw-flex tw-py-0.5 tw-items-center"
											>
												<v-img
													:alt="item.name"
													:src="
														$image(
															$get(
																product,
																'attachments.0.attachedLink'
															)
														)
													"
													cover
													ref="opImgRef"
													class="tw-flex-none tw-w-8 tw-h-8 tw-cursor-pointer tw-me-2 tw-rounded"
													@error="
														$onImgError({
															index,
															size: '32',
															ref: $refs.opImgRef,
														})
													"
												/>
												<div class="tw-flex-1">
													{{ product.name }}
												</div>

												<div
													class="tw-w-1/6 tw-flex-none tw-text-right"
												>
													{{
														$commafy(
															product.pivot.productPrice.toFixed(
																2
															)
														)
													}}
												</div>

												<div
													class="tw-w-1/6 tw-flex-none tw-text-center"
												>
													x{{
														product.pivot.quantity * item.quantity
													}}
												</div>

												<div
													class="tw-w-1/6 tw-flex-none tw-text-right"
												>
													{{
														$commafy(
															(
																product.pivot.productPrice *
																product.pivot.quantity *
																item.quantity
															).toFixed(2)
														)
													}}
												</div>
											</div>
											<v-divider class="tw-my-2"></v-divider>
											<div class="tw-flex tw-justify-between">
												<div>{{ $t('products-total') }}</div>
												<div class="tw-text-right">
													{{
														$commafy(
															(
																item.priceWod * item.quantity
															).toFixed(2)
														)
													}}
												</div>
											</div>
											<div class="tw-flex tw-justify-between">
												<div>{{ $t('discount') }}</div>
												<div class="tw-text-right error--text">
													-{{
														(item.priceWod - item.price) *
														item.quantity
													}}
												</div>
											</div>
											<v-divider class="tw-my-2"></v-divider>
											<div class="tw-flex tw-py-0.5">
												<div class="tw-flex-1">
													{{ $t('total') }}
												</div>
												<div class="tw-w-1/5 tw-flex-none"></div>
												<div
													class="tw-w-1/5 tw-flex-none tw-text-right"
												>
													{{
														$commafy(
															(
																item.price * item.quantity
															).toFixed(2)
														)
													}}
												</div>
											</div>
										</v-col>
									</v-row>
								</v-container>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</v-expansion-panels>
				</template>

				<v-container fluid class="tw-mt-2">
					<!-- SUBTOTAL -->
					<div class="tw-flex tw-justify-between lg:tw-text-xl lg:tw-mb-3">
						<span class="tw-text-beb">{{ $t('subTotal') }}</span>
						<span
							>{{ $t('sar') }}
							{{ $commafy(mOrder.subtotal.toFixed(2)) }}</span
						>
					</div>

					<!-- VAT & TAX -->
					<div class="tw-flex tw-justify-between lg:tw-text-xl lg:tw-mb-3">
						<span class="tw-text-beb">{{ $t('tax-15') }}</span>
						<span>
							{{ $t('sar') }}
							{{ $commafy(mOrder.tax.toFixed(2)) }}
						</span>
					</div>

					<!-- DISCOUNT -->
					<div
						class="tw-flex tw-justify-between lg:tw-text-xl lg:tw-mb-3 error--text"
					>
						<span class="tw-text-beb">{{ $t('discount') }}</span>
						<span
							>- {{ $t('sar') }}
							{{ $commafy(mOrder.discount.toFixed(2)) }}</span
						>
					</div>

					<div class="tw-flex tw-justify-between lg:tw-text-xl lg:tw-mb-3">
						<span class="tw-text-beb">{{ $t('shippingFree') }}</span>
						<span class="tw-text-bad">{{ $t('free') }}</span>
					</div>

					<v-divider class="tw-my-4 lg:tw-my-8"></v-divider>

					<div
						class="tw-text-beb xl:tw-text-xl tw-items-center tw-justify-between tw-flex"
					>
						<div
							class="tw-flex tw-space-s-2 lg:tw-space-s-3.5 tw-items-center"
						>
							<span>{{ $t('total-price') }}</span>
							<span
								class="tw-w-2 tw-h-2 tw-block tw-rounded-full tw-bg-F2"
							></span>
							<span>{{ $t('inclusive-of-value-added-tax') }}</span>
						</div>
						<div
							class="tw-font-rubi tw-text-black tw-text-22 tw-font-medium"
						>
							{{ $t('sar') }}
							{{ $commafy(mOrder.total.toFixed(2)) }}
						</div>
					</div>
				</v-container>
			</div>
		</div>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'OrderViewDialog',
	props: {
		printLoading: Boolean,
		translatedStatuses: Array,
		order: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			offerPanels: [],
			dialogOrderTracking: false,
		}
	},
	computed: {
		...mapGetters('auth', ['$currentUser']),
		mOrder: {
			get() {
				return this.order
			},
			set(v) {
				this.$emit('update:order', v)
			},
		},
	},
	methods: {
		...mapActions('orders', ['changeStatusOfOrder']),
		handlePrint() {
			this.$emit('print', this.mOrder.id)
		},
		onChangeStatus(item, status) {
			return this.changeStatusOfOrder({
				status,
				id: item.id,
			})
		},
	},
}
</script>

<style lang="scss">
.mOrder-view {
	.v-expansion-panel-content__wrap {
		padding: 12px !important;
	}
}
</style>
