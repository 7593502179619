<template>
	<div>
		<base-data-table
			:add="false"
			:title="$t('orders')"
			:headers="headers"
			:actions="actions"
			:provider="$orders"
			:fetch-function="onFetchOrders"
			@view="onViewOrder"
			@delete="openConfirmDialog"
		>
			<!-- SEARCH -->
			<template #search>
				<v-btn
					depressed
					height="48"
					color="primary"
					@click="onToggleSearch"
				>
					<v-icon v-if="search.isExpanded">mdi-close</v-icon>
					<v-icon v-else>mdi-filter</v-icon>
				</v-btn>
			</template>

			<template v-if="search.isExpanded" #extension>
				<div class="tw-bg-gray-200 tw-p-4 tw-mx-4 tw-rounded">
					<div class="tw-grid tw-gap-4 tw-grid-cols-2">
						<v-text-field
							v-model="search.form.orderNumber"
							outlined
							clearable
							hide-details
							:label="$t('order-number')"
							placeholder="e.g. 12345678"
						/>
						<v-select
							v-model="search.form.status"
							:label="$t('statusText')"
							:items="translatedStatuses"
							outlined
							multiple
							clearable
							hide-details
							item-value="value"
							item-text="label"
						/>
						<div class="tw-flex tw-space-x-4 tw-col-span-2">
							<base-date-picker
								v-model="search.form.fromDate"
								:append-icon="false"
								:placeholder="$t('from-date')"
								clearable
								prepend-inner-icon
								class="tw-flex-1"
							/>
							<base-date-picker
								v-model="search.form.toDate"
								:append-icon="false"
								:placeholder="$t('to-date')"
								clearable
								class="tw-flex-1"
							/>
						</div>
					</div>
					<div
						class="tw-flex tw-justify-end tw-space-x-2 tw-items-end tw-mt-4"
					>
						<v-btn text color="primary" @click="onToggleSearch">
							<span class="tw-normal-case">{{ $t('close') }}</span>
						</v-btn>
						<v-btn text color="primary" @click="onResetSearch">
							<span class="tw-normal-case">{{ $t('reset') }}</span>
						</v-btn>
						<base-button
							:loading="search.isLoading"
							@click="onSearchOrders"
						>
							{{ $t('actions.search') }}
						</base-button>
					</div>
				</div>
			</template>
			<!-- SEARCH END -->

			<!-- SERIAL -->
			<template v-slot:[`item.serial`]="{ index }">
				{{ $orders.perPage * ($orders.currentPage - 1) + index + 1 }}
			</template>

			<!-- STATUS -->
			<template v-slot:[`item.status`]="{ item }">
				<BaseStatus v-if="$currentUser.isBuyer" :status="item.status" />
				<div v-else class="tw-w-40">
					<BaseStatusChanger
						:value="item.status"
						:items="translatedStatuses"
						:on-change="(v) => onChangeStatus(item, v)"
					/>
				</div>
			</template>

			<!-- PAYMENT METHOD -->
			<template v-slot:[`item.paymentMethod`]="{ item }">
				<div
					v-if="item.payments.length"
					class="tw-text-black tw-font-semibold tw-capitalize tw-text-sm"
				>
					{{ item.payments[0].paymentMethod }}
				</div>
			</template>

			<!-- ACTIVATION DATE -->
			<template v-slot:[`item.activatedAt`]="{ item }">
				<div>
					{{ $m(item.activatedAt).format('YYYY-MM-DD') }}
				</div>
			</template>

			<!-- PAYMENT -->
			<template v-slot:[`item.payment`]="{ item }">
				<div
					v-if="$get(item, 'payments.0.paymentStatus') === 'authorised'"
					class="tw-text-sm tw-font-semibold tw-text-AA tw-capitalize"
				>
					{{ $t('paid') }}
				</div>

				<template v-else-if="$currentUser.isBuyer">
					<div v-if="item.status !== 'cancelled'" class="md:tw-w-full">
						<v-btn
							class="tw-rounded-lg"
							width=""
							height="44"
							outlined
							@click="onClickPayNow(item)"
							color="#F2CA51"
						>
							{{ $t('payNow') }}
						</v-btn>
					</div>
					<div
						v-else-if="item.status === 'cancelled'"
						class="tw-tracking-wider tw-uppercase"
					>
						{{ $t('unpaid') }}
					</div>
				</template>

				<div v-else class="tw-text-AA tw-capitalize">
					{{ $get(item, 'payments.0.paymentStatus') }}
				</div>
			</template>
		</base-data-table>

		<v-dialog
			max-width="1198"
			v-model="orderForm.dialog"
			content-class="tw-rounded-2xl tw-shadow-lg"
		>
			<OrderViewDialog
				:order.sync="orderForm.data"
				:permissions="$permissions.buyer"
				:onView="onView"
				:onUpdate="onUpdate"
				:translatedStatuses="translatedStatuses"
				:printLoading="printLoading"
				@onCloseView="this.onView = false"
				@changeStatus="onChangeStatus"
				@print="handlePrintOrder"
			/>
		</v-dialog>

		<DialogDeleteConfirmation
			v-model="confirmDialog"
			:id="onDeleteId"
			:title="$t('order')"
			:delete-function="deleteOrder"
		/>
	</div>
</template>

<script>
import api from '@/api'

import { omitEmpties } from 'vuelpers'
import { mapGetters, mapActions } from 'vuex'
import { PAYTAB_PAYMENT_TYPE } from '@/consts'

import OrderViewDialog from '@/components/dialogs/OrderViewDialog.vue'
import DialogDeleteConfirmation from '../../components/dialogs/DialogDeleteConfirmation.vue'

const initialSearchForm = () => {
	return {
		orderNumber: '',
		status: '',
		fromDate: '',
		toDate: '',
	}
}

export default {
	name: 'OrdersView',
	components: {
		OrderViewDialog,
		DialogDeleteConfirmation,
	},
	data() {
		return {
			search: {
				isLoading: false,
				isExpanded: false,
				form: initialSearchForm(),
			},

			orderForm: {
				dialog: false,
				data: null,
			},

			error: [],
			userData: {},
			onView: false,
			onUpdate: false,

			printLoading: false,
			confirmDialog: false,
			onDeleteId: null,
			onDeactiveId: null,
			onChangeStatusData: {},
			confirmStatusDialog: false,
			statuses: [
				{ value: 'new', label: 'status.new' },
				{ value: 'processing', label: 'status.processing' },
				{ value: 'on-delivery', label: 'status.on-delivery' },
				{ value: 'cancelled', label: 'status.cancelled' },
				{ value: 'delivered', label: 'status.delivered' },
			],
		}
	},
	created() {
		this.getPermissions()
	},
	computed: {
		...mapGetters('orders', ['$orders']),
		...mapGetters('auth', ['$currentUser']),
		...mapGetters('permission', ['$permissions']),
		translatedStatuses() {
			return this.statuses.map((status) => ({
				...status,
				label: this.$t(status.label),
			}))
		},
		headers() {
			return [
				{
					text: 'NS',
					align: 'start',
					value: 'serial',
				},
				{
					align: 'start',
					value: 'orderNumber',
					text: this.$t('order-number'),
				},
				{
					value: 'total',
					text: this.$t('price'),
					computed: (v) => `${this.$t('sar')} ${v.totalPrice.toFixed(2)}`,
				},
				{
					value: 'type',
					text: this.$t('type'),
					computed: (v) => this._.capitalize(v.type),
				},
				{ text: this.$t('headers.dateOfOrder'), value: 'activatedAt' },
				{
					value: 'paymentMethod',
					text: this.$t('payment-method'),
				},
				{ text: this.$t('headers.status'), value: 'status' },
				{ text: this.$t('payment'), value: 'payment', align: 'start' },
				{ text: '', value: 'actions' },
			]
		},
		actions() {
			return [
				{
					icon: 'mdi-eye-outline',
					event: 'view',
					text: this.$t('view-details'),
				},
			]
		},
	},
	methods: {
		...mapActions('orders', [
			'getOrders',
			'updateOrder',
			'printOrder',
			'deleteOrder',
			'changeStatusOfOrder',
		]),
		...mapActions('permission', ['getPermissions']),
		...mapActions('settings', ['setSettingsState']),
		async onFetchOrders(query = {}) {
			this.search.isLoading = true
			const res = await this.getOrders({
				...query,
				filter: omitEmpties(this.search.form),
			})
			this.search.isLoading = false
			return res
		},
		onSearchOrders() {
			return this.onFetchOrders({ page: 1 })
		},
		onResetSearch() {
			this.search.form = initialSearchForm()
			this.onSearchOrders()
		},
		onToggleSearch() {
			this.search.isExpanded = !this.search.isExpanded
			if (!this.search.isExpanded) this.onResetSearch()
		},
		openConfirmDialog(order) {
			this.onDeleteId = order.id
			this.confirmDialog = true
		},
		onViewOrder(order) {
			this.orderForm.data = order
			this.orderForm.dialog = true
			this.onView = true
		},
		async onClickPayNow(order) {
			let [err, res] = await api.post('payment/create', {
				currency: 'SAR',
				orderId: order.id,
				shippingAddressId: order.shippingAddress[0].id,
				type: PAYTAB_PAYMENT_TYPE.CART,
			})
			this.setSettingsState({
				currentPayment: {
					tranRef: res.tranRef,
					type: PAYTAB_PAYMENT_TYPE.CART,
				},
			})
			!err && location.replace(res.url)
		},
		async handlePrintOrder(id) {
			this.printLoading = true
			await this.printOrder(id)
			this.printLoading = false
		},
		onChangeStatus(item, status) {
			return this.changeStatusOfOrder({
				status,
				id: item.id,
			})
		},
	},
}
</script>
